import React from 'react';

export function FranceHero() {
  return (
    <div className="flex md:flex-row flex-col items-center justify-center gap-20 max-w-7xl mx-auto pt-32 md:px-0 px-6">
      <div>
        <p className="text-xl mb-4">
          Chaque année, l’Etat français consacre près de 20% de son budget national à l’éducation. Soit 10000 euros par
          étudiant, par an et ceci sans distinction de nationalité. L’excellence du système éducatif français n’est plus
          à démontrer. Il met à la disposition des étudiants en France, des centres de recherches et un grand réseau de
          plus d’une centaine d’universités.
        </p>
        <p className="text-xl">
          Notre agence vous accompagne dans le processus de votre voyage, qui vous fera vivre une belle expérience
          d’études supérieures.
        </p>
        <div className="pt-10">
        </div>
      </div>
      <img src="/assets/herobelgium.jpg" width={500} height={500} className="rounded-xl" alt="" />
    </div>
  );
}
