import React from 'react';

export function BelgiumHero() {
  return (
    <div className="flex md:flex-row flex-col items-center justify-center gap-20 max-w-7xl mx-auto pt-32 md:px-0 px-6">
      <div>
        <p className="text-xl mb-4">
          Avec plus de 50000 étudiants étrangers par an, la Belgique se classe parmi les pays disposant d’un système
          d’accompagnement et de suivi de l’étudiant. Pays multilingue avec une éducation de qualité, des coûts d’études
          et de vie abordables, les étudiants d’un peu partout dans le monde trouvent d’intérêts à poursuivre leurs
          études sur le territoire belge.
        </p>
        <p className="text-xl">
          Nous vous proposons notre assistance pour mener les démarches de voyage pour vos études en Belgique.
        </p>
      </div>
      <img src="/assets/pictures/belgique-students.jpg" width={500} height={500} className="rounded-xl" alt="" />
    </div>
  );
}
