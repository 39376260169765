import React from 'react';

export function CanadaHero() {

  return (
    <div className="flex md:flex-row flex-col items-center justify-center gap-20 max-w-7xl mx-auto pt-32 md:px-0 px-6">
      <div>
        <p className="text-xl mb-4">
          Le Canada vous offre de nombreux programmes d’études supérieures dans de diverses disciplines et dans les
          meilleures universités sur la scène internationale. Les diplômés universitaires au Canada, obtiennent parfois
          la résidence permanente à la fin de leurs cursus académiques. Ils se sentent comme chez eux, grâce à la
          diversité culturelle des terres canadiennes.
        </p>
        <p className="text-xl">
          Edukenvol vous propose ses services de conseils et de guide de voyage, pour vos études à l’étranger.
        </p>
      </div>
      <img src="/assets/pictures/canada-students.jpg" width={500} height={500} className="rounded-xl" alt="" />
    </div>
  );
}
